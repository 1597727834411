@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
  @import "base/_vars";
  @import "base/_themes";
  @import "base/_fonts";
  @import "base/_media";
  @import "base/_typography";
  @import "base/_forms";
  @import "base/_buttons";
}

@layer components {
  @import "components/_site-grid";
  @import "components/_footer";
}

canvas,
img,
picture,
video {
  display: block;
  max-width: 100%;
}
.contain {
  position: relative;
  .ar.has-fill {
    width: 100%;
    height: 100%;
    img {
      object-fit: contain;
      object-position: 50% 100%;
    }
  }
}
.ar {
  @apply relative overflow-hidden;

  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    opacity: 0;
    width: calc(100% + 1px); /* helps with half pixel renderings */
    height: calc(100% + 1px); /* helps with half pixel renderings */
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.3s ease-out;

    &.is-loaded {
      opacity: 1;
    }

    &.static {
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      transform: translate3d(0, 0, 0);
    }
  }

  &.has-fill,
  &.has-fill picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      object-fit: cover;
      top: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
      width: 100%;
      height: 100%;
    }
  }

  &--placeholder {
    position: absolute;
    transition: opacity 0s 0.3s ease-out;

    &.is-loaded {
      opacity: 0;
    }

    &::after {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(1rem) contrast(1.5);
      content: "";
    }

    img {
      opacity: 0;
    }
  }
}

.btn {
  @apply uppercase px-3 py-1 rounded-[4px] font-sansRegular tracking-[0.04em];
}

.btn-big {
  @apply px-12 py-3;
}

.bg-slate .btn {
  @apply bg-white text-slate;
}

.cta-link {
  @apply border-b font-sansRegular tracking-[0.04em] inline-block uppercase mt-10;
}

.small-link {
  @apply font-sansRegular hover:border-b;
}

.long-arrow {
  width: 32px;
  height: 11px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transition: width 0.3s ease;
  svg {
    position: absolute;
    right: 0;
    width: auto;
    height: 11px;
  }
}

button:hover .long-arrow,
a:hover .long-arrow {
  width: 42px;
}

.parent-footer {
  h4,
  li {
    margin-bottom: 16px;
    line-height: 1;
  }

  a {
    color: #bfbfbf;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    color: #f5f5f5;
  }
}

.z-70 {
  z-index: 70;
}

.form {
  .input-wrap {
    position: relative;
    margin-bottom: 20px;

    &.select {
      .icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        width: 24px;
        height: 24px;
      }
    }

    button {
      border-radius: 6px;
      border: 1px solid #000;
      padding: 10px 50px;
    }

    select,
    input {
      width: 100%;
      height: 100%;
      padding: 12px 14px;
      margin: 0;
      border: 1px solid #000;
      border-radius: 3px;
      @apply bg-gray font-sansRegular tracking-[0.04em];
    }
    select {
      appearance: none;
      cursor: pointer;
    }
    label {
      @apply text-caption uppercase bg-gray absolute font-sansRegular tracking-[0.12em];
      top: -8px;
      left: 12px;
      margin: 0;
      padding: 0 5px;
    }
  }
}

.type {
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.01em;
}

.type--nav {
  @apply text-caption font-sansRegular font-normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.wysiwyg-content,
.rc {
  p:last-child a {
    font-size: 16px;
    @apply border-b font-sansRegular tracking-[0.04em] inline-block uppercase mt-10;
  }
}

.interactive.style--longText {
  .rc {
    p + p {
      margin-top: 40px;
    }
  }
}

@font-face {
  font-family: MediumLLWeb-Regular;
  font-style: normal;
  font-weight: 400;

  font-display: swap;
  src: url("/fonts/MediumLLWeb-Regular.woff2") format("woff2"),
    url("/fonts/MediumLLWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: MediumLLWeb-Book;
  font-style: normal;
  font-weight: 400;

  font-display: swap;
  src: url("/fonts/MediumLLWeb-Book.woff2") format("woff2"),
    url("/fonts/MediumLLWeb-Book.woff") format("woff");
}

@font-face {
  font-family: MediumLLWeb-SemiBold;
  font-style: normal;
  font-weight: 500;

  font-display: swap;
  src: url("/fonts/MediumLLWeb-SemiBold.woff2") format("woff2"),
    url("/fonts/MediumLLWeb-SemiBold.woff") format("woff");
}

@font-face {
  font-family: MediumLLWeb-Light;
  font-style: normal;
  font-weight: 300;

  font-display: swap;
  src: url("/fonts/MediumLLWeb-Light.woff2") format("woff2"),
    url("/fonts/MediumLLWeb-Light.woff") format("woff");
}

main,
header,
section[data-theme],
.grid-block[data-theme],
.card-side[data-theme],
.pricing-block[data-theme],
.interactive.style--longText[data-theme],
.tab-image[data-theme] {
  transition: background 0.7s ease, color 0.7s ease;
  background: var(--background);
}
.tab-image[data-theme] {
  transition: background 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  background: var(--background);
}
.sticky-phrase {
  transition: background 0.7s ease, color 0.7s ease, opacity 0.3s ease;

  .block .bg,
  .backdrop {
    background: var(--background);
    transition: background 0.7s ease, color 0.7s ease, opacity 0.3s ease;
  }
}

.card-side[data-theme] {
  transition: background 0.7s ease, color 0.7s ease, opacity 0.3s ease;
}

header {
  a {
    color: var(--foreground);
  }
  svg path {
    fill: var(--foreground) !important;
  }
  svg line {
    stroke: var(--foreground) !important;
  }
  &.is-white-bg {
    --foreground: var(--slate);
  }
}

[data-theme="yellow"] {
  --background: var(--yellow);
  --foreground: var(--slate);
}

[data-theme="blue"] {
  --background: var(--blue);
  --foreground: var(--slate);
}

[data-theme="slate"] {
  --background: var(--slate);
  --foreground: var(--white);
}

[data-theme="white"] {
  --background: var(--white);
  --foreground: var(--slate);
}

[data-theme="tan"] {
  --background: var(--tan);
  --foreground: var(--slate);
}

[data-theme="gray"] {
  --background: var(--gray);
  --foreground: var(--slate);
}

[data-theme="green"] {
  --background: var(--green);
  --foreground: var(--slate);
}

.diagram-tabs + .grid-section {
  margin-top: 200px;
}

.grid-section + .intro-text {
  margin-top: 50px;
}

.intro-text {
  position: relative;
  z-index: 20;
}
.interactive {
  z-index: 19;
}

.sticky-phrase.pb-20 + .divider {
  margin-top: -5rem;
}

.interactive.style--default {
  z-index: 20;
}

.interactive.style--vertical + .interactive.style--vertical {
  z-index: 18;
}

.interactive.style--vertical
  + .interactive.style--vertical
  + .interactive.style--vertical {
  z-index: 17;
}
.interactive.style--vertical
  + .interactive.style--vertical
  + .interactive.style--vertical
  + .interactive.style--vertical {
  z-index: 16;
}

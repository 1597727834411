:root {
  --header-height: 73px;

  --yellow: theme(colors.yellow);
  --blue: theme(colors.blue);
  --slate: theme(colors.slate);
  --white: theme(colors.white);
  --tan: theme(colors.tan);
  --gray: theme(colors.gray);
  --green: theme(colors.green);

  -webkit-font-smoothing: antialiased;

  --v-spacer-lg: 250px;
  --v-spacer: 125px;

  --site-max-width: 1200px;
  --site-max-width-xl: 1800px;
}

body {
  padding-top: var(--header-height);
  background: var(--gray);
}

.site-container {
  max-width: var(--site-max-width);
  margin: 0 auto;
}

.site-container-xl {
  max-width: var(--site-max-width-xl);
  margin: 0 auto;
}

.site-grid {
  @apply grid grid-cols-20;
}

.equal-phrases {
  .phrase {
    .inline-flex {
      min-width: 240px;
      &:first-child span {
        margin-left: auto;
      }
    }
  }
}

.site-wrap[data-theme="yellow"] .grid-section[data-theme="yellow"] {
  margin-top: 0;
}

.site-wrap[data-theme="yellow"] .header.has-scrolled {
  background: var(--gray);
}
